import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import '../css/App.css';
import { Box, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import "@fontsource/poppins";
import { useParams } from 'react-router-dom';
import amplitude from 'amplitude-js';

function getFormattedPrice(price) {
  let [integerPart, fractionalPart] = price.toFixed(2).split('.');
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  fractionalPart = fractionalPart.replace('.', ',');
  return `${integerPart},${fractionalPart}`;
}

const Terminal = () => {
  const { token } = useParams();
  const [productCode, setProductCode] = useState('');
  const [product, setProduct] = useState(null);
  const [terminal, setTerminal] = useState(null)
  const [productNotFoundMessage, setProductNotFoundMessage] = useState(null);
  const [terminalNotFoundMessage, setTerminalNotFoundMessage] = useState(null)
  const interval = useRef(null);
  const [idDuxFromTerminal, setIdDuxFromTerminal] = useState(null);

  useEffect(() => {
    axios.get(`${window._env_.REACT_APP_BFF_URL}/terminal`, {
      params: {
        terminalToken: token      
      }
    }).then(response => {
        setTerminal(response.data);
        setIdDuxFromTerminal(response.data.idDux);
        setTerminalNotFoundMessage(null);
      })
      .catch(error => {
        if (error.response && error.response.status === 404) {
          setTerminalNotFoundMessage('No se encontro la terminal, verifica el token desde el software de gestion');
        } else {
          setTerminalNotFoundMessage('Ocurrio un error, contacta con el administrador del sistema');
        }
      });
  }, [token])
  
  useEffect(() => {
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      setProductCode('');
      setProduct(null);
      setProductNotFoundMessage(null);
    }, 5000);
  }, [product, productNotFoundMessage]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      logAmplitudeEvent(idDuxFromTerminal, 'TERMINAL_PRECIO_CONSULTAR');
      event.preventDefault();
      const newProductCode = event.target.value;
      setProductCode(newProductCode);
      event.target.value = '';
      axios.get(`${window._env_.REACT_APP_BFF_URL}/productInfo`, {
        params: {
          terminalToken: token,
          productId: newProductCode,
        }
      }).then(response => {
          setProduct(response.data);
          if(response.data.price === null) {
            setProductNotFoundMessage('No hemos podido encontrar un producto con ese código');
          } else {
            setProductNotFoundMessage(null);
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 404) {
            setProductNotFoundMessage('No hemos podido encontrar un producto con ese código');
            setProduct(null);
          } else {
            console.error('Error:', error);
          }
        });
    }
  }, [idDuxFromTerminal]);

  if (terminalNotFoundMessage) {
    return (
     <div className='App'>
       <Typography variant="h2" fontFamily="Poppins" fontWeight={"bold"} className='appTittles'>
         {terminalNotFoundMessage}
       </Typography>
     </div>
    );
    }
    
  return (
    <div className='App' style={{backgroundColor: terminal ? `#${terminal.bgColor}`: '#0763E7',color: terminal ? `#${terminal.fontColor}`: "white"}}>
      {!product && !productNotFoundMessage && (
        <Box className='appTittles'>
        <Typography variant="h1" fontFamily="Poppins" fontWeight={"bold"} fontSize={"4rem"}>
          Escanea el producto para conocer su precio
        </Typography>
        <KeyboardDoubleArrowDownIcon sx={{ fontSize: '4rem' }} />
        </Box>
      )} 
      <input
        type="text"
        autoFocus
        onKeyDown={handleKeyDown}
        className='hiddenInput'
      />
      {productNotFoundMessage && 
        <Typography variant="h2" fontFamily="Poppins" fontWeight={"bold"} className='appTittles'>
        {productNotFoundMessage}
      </Typography>
      }
       <Box sx={{ backgroundColor: terminal ? `#${terminal.bgColor}`: '#0763E7', padding: 2}}>
        {product && product.price !== null && (
          <Card sx={{ backgroundColor: terminal ? `#${terminal.bgColor}`: '#0763E7', padding: 2 ,border: product ?  (terminal ? `1px solid #${terminal.fontColor}`:'1px solid white') : 'none', borderRadius: '4px', display: 'inline-block'}}>
          <CardContent>
            <Typography variant="h3" fontFamily="Poppins" fontWeight={"bold"} style={{color: terminal ? `#${terminal.fontColor}`: 'white'}} sx={{fontSize: '5rem'}}>
              {product.name}
            </Typography>
            <Typography variant="h1" fontFamily="Poppins" fontWeight={"bold"} style={{color: terminal ? `#${terminal.fontColor}`: 'white'}} sx={{fontSize: '10rem'}}>
              $ {getFormattedPrice(product.price)}
            </Typography>
          </CardContent>
        </Card>
        )}
      </Box>
    </div>
  )
}


amplitude.getInstance().init('dd543f90b0f2dcd65b82e7127f18ad13');

function logAmplitudeEvent(idDux, eventName) {
  try {
    let eventProperties = {
      idDux: idDux,
    };

    const identify = new amplitude.Identify().set('idDux', idDux);

    amplitude.getInstance().identify(identify);
    amplitude.getInstance().logEvent(eventName, eventProperties);
  } catch (e) {
    console.error(e);
  }
}

export default Terminal;