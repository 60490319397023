import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Terminal from './Terminal';
import Footer from './Footer';
import Home from './Home';

function App() {
 return (
   <Router>
     <Routes>
        <Route path='/' element={<Home />} />
       <Route path="/:token" element={<Terminal />} />
     </Routes>
     <Footer />
   </Router>
 );
}

export default App;